import { IFrontiersImageProps } from "@frontiers-vue3/public-pages";
import {
  getTransformedAssetUrlList,
  getTransformedAssetUrlListByMedia
} from "~/helpers";
import { IContentfulAsset } from "~/types/contentful/abstract";
import {
  IContentfulImagesQuery,
  IContentfulImagesQueryByMedia
} from "~/types/contentful/assets";

const mapAssetToFrontiersImage = (
  cms: IContentfulAsset,
  imgQueries?: IContentfulImagesQuery[],
  lazyLoadingAttr?: boolean
): IFrontiersImageProps => {
  return {
    sources: imgQueries
      ? getTransformedAssetUrlList(cms.url, imgQueries)
      : [cms.url],
    imageAlt: cms.title ? cms.title : undefined,
    figcaption: cms.description ? cms.description : undefined,
    lazyLoadingAttr: lazyLoadingAttr ? lazyLoadingAttr : false
  };
};

const mapAssetToFrontiersImageByMedia = (
  cms: IContentfulAsset,
  imgQueries: IContentfulImagesQueryByMedia[],
  lazyLoadingAttr: boolean
): IFrontiersImageProps => {
  return {
    sources: getTransformedAssetUrlListByMedia(cms.url, imgQueries),
    imageAlt: cms.title ? cms.title : undefined,
    figcaption: cms.description ? cms.description : undefined,
    lazyLoadingAttr
  };
};

export { mapAssetToFrontiersImage, mapAssetToFrontiersImageByMedia };
