import { IImageSource } from "@frontiers-vue3/public-pages";
import {
  IContentfulImagesQuery,
  IContentfulImagesQueryByMedia
} from "~/types/contentful/assets";

export function getTransformedAssetUrlList(
  assetUrl: string,
  imgQueries: IContentfulImagesQuery[]
): Array<string> {
  return imgQueries.map((prop: IContentfulImagesQuery) => {
    return migrateUrl(assetUrl, prop);
  });
}

export function getTransformedAssetUrlListByMedia(
  assetUrl: string,
  imgQueriesByMedia: IContentfulImagesQueryByMedia[]
): IImageSource[] {
  return imgQueriesByMedia.map((img: IContentfulImagesQueryByMedia) => {
    return {
      media: img.media,
      src: migrateUrl(assetUrl, img.query)
    };
  });
}

function migrateUrl(assetUrl: string, prop: IContentfulImagesQuery) {
  let url: string = assetUrl + "?";

  const {
    width,
    height,
    focusImage,
    format,
    scaleImage,
    radius,
    quality,
    bgColor,
    progressiveJpg
  } = prop || {};

  //* Specify width of an image.
  width && (url += `&w=${width}`);

  //* Specify height of an image.
  height && (url += `&h=${height}`);

  //* Specify image format.
  format && (url += `&fm=${format}`);

  //* Specify focus area when resizing.
  focusImage && (url += `&f=${focusImage}`);

  //* Change the resizing behavior.
  scaleImage && (url += `&fit=${scaleImage}`);

  //* Make image and ellipse or change radius
  radius && (url += `&r=${radius}`);

  //* To alter the quality, possible values - 1 to 100, Won't work in png8
  quality && (url += `&q=${quality}`);

  //* To change the background color, possible values - Only rgb hexa
  bgColor && (url += `&bg=rgb:${bgColor}`);

  //* It stores multiple passes of an image in progressively higher detail.
  progressiveJpg && (url += `&fl=progressive`);

  return url;
}
